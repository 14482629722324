AOS.init();

let body = document.querySelector('body')
let header = document.querySelector('.header')
let headerBtn = document.querySelector('.toggle-menu')

headerBtn.addEventListener('click', function () {
    headerBtn.classList.toggle('active')
    header.classList.toggle('active')
    body.classList.toggle('body-hidden')
})

let ourServices = new Swiper('.our-services-content', {
    lazy: true,
    wrapperClass: "our-services-wrapper",
    slideClass: "our-services-slide",
    slidesPerView: 2,
    loop: true,
    spaceBetween: 20,
    breakpoints: {
        600: {
            slidesPerView: 3,
        },
        992: {
            slidesPerView: 4,
        },
        1200: {
            slidesPerView: 5,
        },
    },
    pagination: {
        el: '.swiper-pagination',
        dynamicBullets: true,
        dynamicMainBullets: 1
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
});

function scrollToElement(element) {
    if (element.length) {
        $('html, body').animate({
            scrollTop: parseInt(element.offset().top) - 100
        }, 500);
    }
}

$(document).on('click', '[data-scroll]', function (e) {
    e.preventDefault()
    scrollToElement($('#' + $(this).attr('data-scroll')));
    if(innerWidth <= 992) {
        headerBtn.classList.toggle('active')
        header.classList.toggle('active')
        body.classList.toggle('body-hidden')
    }
})
